<template>
	<div class="main">
		<div class="secondary-banner">
			<img :src="img_list[0].img" alt="" style="width: 100%;">
		</div>
		<div class="humanresources container-secondary-main">
			<div class="humanresources-type">
				<h6>Human Resources</h6>
				<h5>{{lang.human_resources}}</h5>
				<div @click="path('/humanresources/index/1')"><p :class="type==1?'active cursor':'cursor'">{{lang.human_recruitment}}</p></div>
				<div @click="path('/humanresources/index/2')"><p :class="type==2?'active cursor':'cursor'">{{lang.human_use_idea}}</p></div>
			</div>
			<div class="humanresources-content">
				<Job v-if="type==1"></Job>
				<div v-if="type==2" class="idea">
					<div v-html="info"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Job from '../../components/humanresources/Job.vue';
	import { getArticleInfo } from "@/api/index";
	import { getAdList } from '@/api/store.js'
	import {bannerType} from "@/utils/type.js"
	import {
		mapState
	} from 'vuex'
	export default {
		name: "humanresources",
		components:{ 
			Job
		},
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				type_id:'5',
				type: '',
				info:'',
				bannerId:bannerType.humanResources,
				img_list:[{img:''}],
			}
		},
		watch: {
			'$route'(nval) {
				this.type = nval.params.id
			}
		},
		props:['id'],      // 将路由中传递的参数id解耦到组件的props属性上
		mounted(){
			this.type=this.id
		},
		created() {
			this.getArticleInfo()
			this.getAdListFun()
		},
		methods:{
			getAdListFun(){
				// 获取广告位图片
				let data = {
					cate_id:this.bannerId
				}
				getAdList(data).then(res=>{
					if(res.data.img_list){
						this.img_list = res.data.img_list
					}
					
				})
			},
			getArticleInfo(){
				let data = Object.assign({type_id:this.type_id}); 
				getArticleInfo(data).then(res=>{
					
					this.info=res.data.article_info.info
				})
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}




	}
</script>

<style lang="less">
	.main{
		background-color: #f2f2f2;
		.humanresources{
			margin-top: 1.5rem;
			display: flex;
			justify-content: space-between;
			.humanresources-type{
				width: 19%;
				text-align: center;
				background-color: #fff;
				padding: 1.75rem 2.5rem;
				h6{
					font-size: 0.75rem;
					font-weight: bold;
					color: #333;
				}
				h5{
					font-size: 1rem;
					font-weight: bold;
					color: #333;
					line-height: 2.125rem;
					background-image: url('../../assets/humanresources/humanresources_type_title_bj.png');
					background-size: 100% 100%;
					width: 6.8125rem;
					height: 2.125rem;
					margin: auto;
					margin-bottom: 3.125rem;
				}
				div{
					p{
						background: #fff;
						border-radius: 2rem;
						font-size: 1rem;
						color: #333;
						padding:1rem 0rem;
						margin-bottom: 2.125rem;
					}
					p.active{
						background: #B60914;
						color: #FFFFFF;
					}
				}
				
			}
			.humanresources-content{
				width: 79%;
				.humanresources-titile{
					border-left: 0.25rem solid #D70C19;
					padding-left: 0.375rem;
					font-size: 1rem;
					margin-bottom: 1.6875rem;
					color: #333333;
				}
				.idea{
					background-color: #fff;
					padding: 5.9375rem 1.75rem;
				}
			}
		}
		
	}
	@media (max-width: 1220px) {
		.main .humanresources .humanresources-type {
			padding: 1.75rem 1rem;
		}
	}
	@media (max-width: 853px) {
		.job-table th {
		    font-size: 0.75rem;
		}
		.job-table td {
		    width: auto;
		}
	}
	@media (max-width: 768px) {
		    .main .humanresources .humanresources-type {
		        padding: 1.75rem 1rem;
		        width: 100%;
		        float: none;
		        height: 13rem;
		        margin-bottom: 2rem;
		    }
			.main .humanresources .humanresources-type h5 {
			    margin-bottom: 2.125rem;
			}
			.main .humanresources .humanresources-type div{
				float: left;
				width: 45%;
				margin:0 2.5%;
			}
			.main .humanresources .humanresources-content {
			    width: 100%;
			}
			.main .humanresources {
			    display: block;
			}
			.el-dialog{
				width: 88% !important;
			}
	}
	
</style>