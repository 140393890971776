<template>
	<div class="main">
		<div class="job container-secondary-main" v-if="onpageshow==1">
			<h6 class="humanresources-titile">{{lang.human_recruiting_positions}}</h6>
			<searchLine @update-data="handleData"></searchLine>
			<div class="job-list cursor" v-for="hire in hire_list.data" @click="detail(hire.id,2)">
				<h4>{{hire.title}}<span>{{hire.salary_range}}</span></h4>
				<div class="label"><el-tag type="info" v-if="hire.tag" v-for="tag in hire.tag">{{tag}}</el-tag></div>
				<p >{{stripHTMLTags(hire.job_demand)}}</p>
			</div>
			<div v-if="hire_list.data.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
				<img class="type-image" src="@/assets/nodata.gif" alt=""/>
				<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
			</div>
			<div class="pagination" v-if="hire_list.data.length > 0">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:layout="layout"
				:current-page.sync="current.page" :page-size="current.per_page"   :pager-count="5" :total="hire_list.total">
				</el-pagination>
			</div>
		</div>
		<div class="job-detail container-secondary-main" v-if="onpageshow==2">
			<p class="humanresources-back cursor" @click="backList(1)"><i class="el-icon-back"></i>{{lang.return}}</p>
			<table class="job-table">
				<tR>
					<th>{{lang.human_apply_work}}</th>
					<th>{{lang.human_apply_need_num}}</th>
					<th>{{lang.human_apply_salary_range}}</th>
					<th>{{lang.human_apply_location}}</th>
					<th>{{lang.human_apply_time}}</th>
				</tR>
				<tr>
					<td width="150">{{hire_info.title}}</td>
					<td width="100">{{hire_info.need_people}}</td>
					<td width="150">{{hire_info.salary_range}}</td>
					<td>{{hire_info.place}}</td>
					<td width="150">{{hire_info.add_time}}</td>
				</tr>
				<tr>
					<td colspan="5">
						<div>
							<p v-html="hire_info.info"></p>
							
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="5">
						<div>
							<p v-html="hire_info.job_demand"></p>
						</div>
					</td>
				</tr>
			</table>
			<el-button class="job-up cursor" @click="jobUp">{{lang.human_apply_online}}</el-button>
		</div>

		<el-dialog :close-on-click-modal="false" :title="lang.human_apply_online" :visible.sync="dialogTableVisible" width="70%">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item :label="lang.human_apply_name" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_sex" prop="sex">
						<el-radio-group v-model="ruleForm.sex">
							<el-radio label="1">{{lang.man}}</el-radio>
							<el-radio label="2">{{lang.woman}}</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item :label="lang.human_apply_birth" >
						<el-input v-model="ruleForm.birthday"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_native_place" prop="native_place">
						<el-input v-model="ruleForm.native_place"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_phone" prop="phone">
						<el-input v-model="ruleForm.phone"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_email" prop="email">
						<el-input v-model="ruleForm.email"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_education" prop="education">
						<el-input v-model="ruleForm.education"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_major" prop="major">
						<el-input v-model="ruleForm.major"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_school" prop="school">
						<el-input v-model="ruleForm.school"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_address" prop="address">
						<el-input v-model="ruleForm.address"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_job_list" prop="work_experience">
						<el-input type="textarea" v-model="ruleForm.work_experience"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_hobby" prop="hobby">
						<el-input type="textarea" v-model="ruleForm.hobby"></el-input>
					</el-form-item>
					<el-form-item :label="lang.human_apply_code" prop="captcha">
						<el-input v-model="ruleForm.captcha" style="width:200px;max-width: 100%;"></el-input>
						<img :src="imgCode" height="40px" @click="refreshCaptcha"/>
					</el-form-item>
					<el-form-item style="text-align: center;">
						<el-button type="primary" @click="submitForm('ruleForm')">{{lang.commit}}</el-button>
					</el-form-item>
				</el-form>
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import searchLine from '../../components/searchLine.vue';
	import { getHireList,getHireInfo,applyJob } from "@/api/index";
	import { BASE_API } from '@/utils/request'
	import axios from 'axios';
	import {
		mapState
	} from 'vuex'
	export default {
		name: "Job",
		components:{
			searchLine
		},
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			}),
			layout() {
			  return this.isMobile ? 'prev, pager, next' : 'prev, pager, next, jumper'; // 根据isMobile的值动态设置layout属性值。如果isMobile为true，则不显示跳转输入框。
			}
		},
		data() {
			return {
				// 分页
				current: {
				  page: 1,
				  per_page: 6
				},
				isMobile: false,	  
				keywords:'',
				onpageshow: 1,
				dialogTableVisible: false,
				fileList: [],
				uploadPath: '',
				hire_list:[],
				hire_info:[],
				imgCode:'',
				ruleForm: {
					datas_id:'',
					name: '',
					sex: '1',
					birthday: '',
					native_place: '',
					phone: '',
					email: '',
					education: '',
					school:'',
					major:'',
					address:'',
					work_experience: '',
					hobby:'',
					captcha:'',
					url:''
					//files: []
				},
				rules: {
					name: [{
							required: true,
							message: '请输入您的姓名',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入您的电话',
							trigger: 'blur'
						}
					],
					email: [{
							required: true,
							message: '请输入您的Email地址',
							trigger: 'blur'
						}
					],
					captcha: [{
							required: true,
							message: '请输入验证码',
							trigger: 'blur'
						}
					]
					
					
				}
			}
		},
		created() {
			this.imgCode=BASE_API+'/get_captcha'
			this.url=BASE_API+'/get_captcha'
			this.getHireList()
		},
		mounted() {
		    window.screenWidth = document.body.clientWidth;
			if(window.screenWidth<=768){
				this.isMobile=true
			}else{
				this.isMobile=false
			}
		},
		methods: {
			handleSizeChange(pageSize){
				 this.current.per_page = pageSize;
				 this.getHireList();
			},
			handleCurrentChange(pageSize){
				 this.current.page = pageSize;
				 this.getHireList();
			},
			handleData(data) {
			      this.keywords = data;
				  this.current.page=1
				  this.getHireList();
			},
			getHireList(){
				let data = Object.assign({keywords:this.keywords}); 
				getHireList(data).then(res=>{
					
					this.hire_list=res.data.hire_list
					if(this.hire_list.data){
						this.hire_list.data.map((item,index)=>{
							if(item.tag){
								item.tag = item.tag.split(';');
							}
						});
					}
					
					
				})
			},
			detail(id, type) {
				this.onpageshow = type
				this.ruleForm.datas_id=id
				let data = Object.assign({id:id});
				getHireInfo(data).then(res=>{
					
					this.hire_info=res.data.hire_info
				})
			},
			jobUp(){
				this.dialogTableVisible=true
			},
			submitForm(formName) {
				console.log(formName)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.ruleForm)
						applyJob(this.ruleForm).then(res=>{
							this.$message(res.msg);
							this.$refs[formName].resetFields();
							this.dialogTableVisible=false
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			
			backList(type) {
				this.onpageshow = type
			},
			stripHTMLTags(html) {
			  const doc = new DOMParser().parseFromString(html, 'text/html');
			  return doc.body.textContent || "";
			},
			async fetchCaptcha() {
			      try {
			        const response = await axios.get(this.url, { responseType: 'blob' }); // 使用blob类型接收图片数据
			        this.imgCode = URL.createObjectURL(response.data); // 创建URL来显示图片
			      } catch (error) {
			        console.error('Error fetching captcha:', error);
			      }
			},
			refreshCaptcha() {
			  this.fetchCaptcha(); // 刷新验证码图片，实际应用中可以加入时间戳或随机数来避免缓存问题
			}    

		}




	}
</script>

<style lang="less">
	.job {
		position: relative;
		.job-list {
			min-height: 10.25rem;
			background-color: #fff;
			border-radius: 8px 8px 8px 8px;
			padding: 1.75rem;
			margin-bottom: 1.125rem;
			

			h4 {
				font-weight: bold;
				font-size: 20px;
				color: #333333;

				span {
					font-size: 24px;
					color: #B60914;
					float: right;
				}
			}

			.label {
				margin: 1rem 0;

				.el-tag.el-tag--info {
					margin-right: 1rem;
					margin-bottom: 1rem;
				}
			}

			p {
				font-size: 0.875rem;
				color: #333333;
				margin-bottom: 0;
			}
		}
		.searchLine {
			position: absolute;
			    top: -5px;
			    right: 0;
		}
	}

	.job-detail {
		text-align: center;
		min-height: 10.25rem;
		background-color: #fff;
		border-radius: 8px 8px 8px 8px;
		padding: 1.75rem;
		margin-bottom: 1.125rem;

		.humanresources-back {
			text-align: left;
			font-size: 0.875rem;
			color: #333;

			i {
				font-weight: bold;
				margin-right: 0.15rem;
				font-size: 1rem;
			}
		}

		.humanresources-back:hover {
			color: #B60914;
		}
	}

	.job-table {
		width: 100%;

		tr {
			border: 1px solid #efefef;
		}

		th {
			background-color: #efefef;
			font-weight: bold;
			font-size: 0.875rem;
			color: #333333;
			text-align: center;
			padding: 1rem 0;
		}

		td {
			padding: 1rem 0;
			font-size: 0.875rem;
			color: #333333;
			text-align: center;

			div {
				width: 90%;
				margin: auto;
				text-align: left;
				line-height: 2rem;
				padding: 1rem 0;

				p {
					font-weight: bold;
					margin-bottom: 0;
				}
			}
		}
	}
	.pagination {
		width: 100%;
		margin-bottom: 2rem;
		flex-direction: row-reverse
	}

	.job-up {
		width: 9rem;
		height: 3rem;
		background: #B60914;
		border-radius: 2rem;
		font-size: 1rem;
		color: #FFFFFF;
		text-align: center;
		margin: 2rem 0;
	}
	
	
	@media(max-width: 992px)  {
		.job .searchLine {
		    position: relative; 
		}
		.searchLine .sort {
		    width: 100%;
		}
	}
	
</style>